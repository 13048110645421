.upload-advertisement .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 310px;
  height: 90px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 0px;
  display: table;
}

.upload-advertisement .img-fluid {
  height: 90px;
}

.antd-style .ant-input,
.antd-style .ant-calendar-picker {
  border-radius: 0px;
}

.antd-style.is-invalid .ant-calendar-picker,
.antd-style.is-invalid .ant-input {
  border-color: #f86c6b;
}

.mo-img-fluid .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 100%;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 0px;
  display: table;
}
