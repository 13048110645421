.mo-header-table {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 5px;
}

.mo-header-table .mo-header-button {
  display: grid;
  justify-content: end;
}

.mo-header-table .count {
  border: 1px solid black;
}

.mo-header-button button.btn {
  border-radius: 3px;
  cursor: pointer;
}

.mo-header-table .mo-header-lable {
  border: 1px dashed #4dbd74;
  background-color: rgba(77, 189, 116, 0.1);
  padding: 4px;
  padding-right: 15px;
  padding-left: 6px;
  border-radius: 2px 15px 15px 2px;
  display: grid;
  align-items: center;
  font-weight: bold;
}
/* 
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: Arial;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  list-style: none;
  min-width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  vertical-align: middle;
} */

table tbody .parrent {
  background: #f2f2f2;
}
