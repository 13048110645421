@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto');
@import './global.css';
.app-body {
  /* background-color: rgba(250, 250, 250, 1); */
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

.btn-info {
  color: white !important;
}

/* .card,
.card-header {
  border-color: rgba(236, 239, 241, 1);
} */

.ant-btn-danger {
  color: red !important;
}

.btn-secondary {
  color: #23282c !important;
}

.ant-tag {
  cursor: auto !important;
}

h1 {
  padding: 0px;
  margin: 0px;
}

.navbar-nav .nav-link {
  color: #c8ced3;
}

.sidebar {
  color: #f3f4f5;
  border-right: 1px solid #c8ced3;
}

.sidebar .nav-dropdown.open {
  background-color: rgba(238, 238, 238, 1);
}

.sidebar .nav-dropdown.open .nav-link {
  color: #23282c;
}

.sidebar .nav-dropdown.open .nav-link.active {
  color: white;
}

@media (min-width: 992px) {
  .sidebar-fixed .app-header {
    z-index: 999;
  }
}

.app-header {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(236, 239, 241, 1);
}

.sidebar {
  background: #ffffff;
  border-right: 1px solid rgba(236, 239, 241, 1);
}

.sidebar .nav-link {
  color: #707070;
}

.sidebar .nav-link.active {
  background-color: #25a551;
}

.sidebar .nav-link.active a {
  color: #ffffff;
}

.media-profile {
  padding: 0.75rem 1rem;
  min-height: 70px;
}

.media-profile img {
  width: 50px;
  height: 50px;
  border: 2px solid rgba(39, 174, 96, 1);
}

.media-profile .media-body {
  height: 60px;
  display: flex;
  align-items: center;
}

.media-role {
  margin-top: 10px;
}
.media-role .media-body {
  padding: 5px 0px;
}
.breadcrumb {
  position: relative;
  border-radius: 0;
  border-bottom: 1px solid rgba(236, 239, 241, 1);
}

.breadcrumb a {
  color: #4dbd74 !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.sidebar .nav-link:hover {
  background: #4dbd74;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: '\f040';
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar-upload-small {
  position: relative;
  max-width: 150px;
  background-size: cover;
}

.avatar-upload-small .avatar-edit,
.avatar-upload-small .avatar-delete {
  position: absolute;
  right: 0px;
  z-index: 1;
}

.avatar-upload-small .avatar-delete {
  top: -10px;
  right: -5px;
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.avatar-upload-small .avatar-edit {
  bottom: 0px;
}

.avatar-upload-small .avatar-edit input {
  display: none;
}

.avatar-upload-small .avatar-edit input + label {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload-small .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
/* .avatar-upload-small .avatar-edit input + label:after {
  content: "\f01b";
  font-family: "FontAwesome";
  color: #4dbd74 !important;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
} */
.avatar-upload-small .avatar-preview {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 5px;
  border: 4px solid #d5e9dc !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload-small .avatar-preview > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* dsfadf */

.foto-upload {
  position: relative;
  max-width: 205px;
}
.foto-upload .foto-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  bottom: 0px;
}
.foto-upload .foto-edit input {
  display: none;
}
.foto-upload .foto-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.foto-upload .foto-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.foto-upload .foto-edit input + label:after {
  content: '\f040';
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.foto-upload .foto-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.foto-upload .foto-preview > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* dfasdf */

.btn-edit {
  position: absolute;
  right: 0;
  transform: translate(30px, -15px);
  z-index: 1;
  top: 0;
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.profile {
  position: relative;
  width: 300px;
}

.profile h3 {
  padding: 0px;
  margin: 0px;
}

.href:hover,
.href:visited,
.href:link,
.href:active {
  text-decoration: none;
}

.loading-preview {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #23282c;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jumbotron-change {
  padding: 0px;
}

.d-inline-block img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #4dbd74 !important;
}

.nav-item .show {
  top: -10px;
}

.mo-upload-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: red;
  background-color: rgba(69, 90, 100, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1020;
}

.mo-logo {
  border: #377058 1px solid;
  padding: 5px;
}

.mo-password {
  position: relative;
}

.mo-toggle-password {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(-9px);
  cursor: pointer;
  font-size: 18px;
  z-index: 3;
}

.mo-form-forget-password {
  background: #23282c;
  color: white;
}

.mo-card {
  position: relative;
  overflow: hidden;
}

.mo-card-body {
  position: relative;
  z-index: 1;
}

.mo-page-notif {
  border: 1px solid #4dbd74 !important;
  border-radius: 3px;
  background: #fff;
}

.mo-page-notif .icon {
  background: #4dbd74 !important;
}

.mo-page-notif .message {
  margin: 0;
}
.mo-page-notif .message,
.mo-page-notif .icon {
  padding: 9px;
}

.mo-loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.mo-loader-hidden {
  display: none;
}

.mo-loader .mo-loader-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid black;
  background-color: #23282c;
  opacity: 0.5;
  margin: 0 auto;
}
.mo-loader .mo-loader-card {
  background: #e2e2e2;
  opacity: 1;
  z-index: 2;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d1d4d7;
}

.icon-back {
  font-size: 25px;
}

.sidebar .nav-dropdown .nav-dropdown-items .nav-item .nav-link {
  padding-left: 25px;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.rc-pagination {
  margin: 0px;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e4e7ea;
}

.card .card-body {
  color: '#212121';
}

.ant-breadcrumb a {
  color: #4caf50 !important;
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.rc-pagination-item-active {
  background-color: #4dbd74;
  border-color: #4dbd74;
  outline: none;
  color: #ffffff;
}

.rc-pagination-item:hover {
  border-color: #4dbd74;
}
