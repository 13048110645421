.container-skeleton-screen {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-skeleton-screen {
  width: 200px;
  height: 250px;
  display: flex;
  align-items: center;
  border-top: 1px solid #23282c;
}
.profile-skeleton-screen .avatar {
  float: left;
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}
.profile-skeleton-screen .line {
  float: left;
  width: 120px;
  height: 10px;
  border-radius: 7px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}
.profile-skeleton-screen .avatar + .line {
  width: 110px;
}

.card-skeleton-screen {
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

.menu-skeleton-screen {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid #23282c;
}
.menu-skeleton-screen .avatar {
  float: left;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 25%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}
.menu-skeleton-screen .line {
  float: left;
  width: 140px;
  height: 10px;
  border-radius: 7px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}
.menu-skeleton-screen .avatar + .line {
  width: 140px;
}
.menu-skeleton-screen .line ~ .line {
  background-color: #ddd;
}

.table-skeleton-screen .line {
  float: left;
  width: 100px;
  height: 10px;
  border-radius: 7px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(139, 139, 139, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%,
  100% {
    background-position: 208px;
  }
}

.post {
  width: 50%;
}
.post .line {
  float: left;
  width: 100%;
  height: 10px;
  margin-top: 12px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.post .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}

.pagination .page-item {
  margin: 0px 2px;
  border-radius: 2px;
}

.pagination .page-item .page-link {
  border-radius: 3px;
  color: #707070;
}

.pagination .page-item.active .page-link {
  background: #209506;
  border: 1px solid #209506;
  color: #ffff;
}
