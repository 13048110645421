.mo-button-group {
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
}

.mo-input-select {
  background: none;
}

.mo-filter {
  border-radius: 3px;
}

.form-group legend {
  font-size: 1em;
}
