.mo-grid {
  display: grid;
}

.flex-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mo-count {
  padding: 0px 5px;
  display: grid;
  align-items: center;
  font-weight: bold;
  vertical-align: center;
  margin-left: 10px;
}

.mo-auto-right {
  display: grid;
  grid-template-columns: 1fr auto;
}

.mo-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
