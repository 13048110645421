.flex {
  display: grid;
  margin: 5px 0px;
}

.flex-2 {
  grid-template-columns: 1fr auto;
}

.flex-3 {
  grid-template-columns: auto 1fr auto;
}

.flex-title {
  padding: 4px;
  padding-right: 15px;
  padding-left: 6px;
  display: grid;
  align-items: center;
  font-weight: bold;
  border-left: 3px solid #4dbd74;
}

.section-modal .modal-content {
  border: 1px solid #f4f4f4;
}

.section-modal .modal-header {
  padding: 10px 1rem;
  border-bottom-color: #f4f4f4;
  background-color: #fafafa;
}

.section-modal .modal-footer {
  border-top-color: #f4f4f4;
  background-color: #fafafa;
  padding: 10px 1rem;
}

.section-modal .modal-header .close {
  outline: none;
}
