.mo-container table .btn {
  border-radius: 2px;
}

.mo-card {
  background-color: #fff;
  border-radius: 0px;
  border-color: #e8e8e8;
}

.mo-card-v1 {
  border-color: #e8e8e8;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.09);
}

.mo-card-header {
  background: #fdfdfd;
  border-bottom: 1px dashed #e8e8e8;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
}

.mo-card-header-detail {
  border-bottom: 1px dashed #e8e8e8;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding: 5px 0px;
  margin-bottom: 5px;
}

.mo-card-header .left {
  display: grid;
  grid-template-columns: auto;
}
.mo-card-header .right {
  display: grid;
  grid-template-columns: 1fr auto;
}

.mo-card-header select.form-control:not([size]):not([multiple]) {
  height: calc(1.64844rem + 2px);
}

.mo-header-x1 {
  height: 80px;
}

.mo-card-body label {
  font-weight: bold;
  font-size: 12px;
}

.mo-card-body select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}

.mo-card-body .mo-grid-input {
  display: grid;
  grid-template-columns: auto 1fr;
}

.mo-input-validator {
  position: relative;
}

.mo-card-body .mo-input-validator i {
  position: absolute;
  top: 25%;
  right: 6px;
}

.mo-card-body .ant-alert {
  border-radius: 0px;
  background-color: #fffef8;
  border: 1px solid #fff5db;
  color: #a79d84;
}

.mo-card-body .alert {
  border-radius: 0px;
}

.mo-card-body span {
  font-size: 12px;
}

.mo-card-body label i {
  font-size: 10px;
  font-weight: normal;
  font-family: 'Chinese Quote', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.mo-card-body .nav-tabs .nav-link.active {
  border-radius: 0px;
  border-color: #e8e8e8;
  border-bottom: 1px dashed #e8e8e8;
  background-color: #fdfdfd;
}
.mo-card-body .tab-content {
  border-color: #e8e8e8;
}

.mo-card-body .mo-input-group {
  padding-bottom: 15px;
}

.mo-card-body .mo-input-group b {
  color: #757575;
}

.mo-card-footer {
  background: #fdfdfd;
  border-top: 1px dashed #e8e8e8;
  height: 80px;
  text-align: center;
}

.mo-card-footer .btn {
  margin: 2px;
}
.mo-card-footer-v1 {
  background: #fdfdfd;
  border-top: 1px dashed #e8e8e8;
  text-align: center;
}

.mo-card-footer .btn {
  border-radius: 0px;
}

.upload-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px dashed #dddddd;
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-content: center;
  /* overflow: hidden; */
}

.upload-wrapper input[type='file'] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.upload-wrapper .image-wrapper {
  overflow: hidden;
}

.upload-btn-close {
  color: #fff;
  height: 20px;
  width: 20px;
  z-index: 1;
  position: absolute;
  right: 0;
  top: -10px;
  background: red;
  border-radius: 30px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* style form  */

.button-group-radio label {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 5px 10px;
}

.button-group-radio label input {
  margin-right: 5px;
}

.button-group-radio label {
  margin-right: 5px;
}
.button-group-radio label:last-child {
  margin-right: 0px;
}
