.mo-dropdown.dropdown-menu {
  box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
    0 0.126rem 0.225rem rgba(122, 123, 151, 0.3) !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(7, 9, 25, 0.15);
  border-radius: 0.29rem;
  padding: 5px;
}

.mo-dropdown.dropdown-menu .list-group-item {
  padding: 5px;
  padding-left: 10px;
  margin: 2px 0px;
  border-radius: 5px;
  border: 1px solid #f4f4f4;
  cursor: pointer;
}

.mo-dropdown.dropdown-menu .list-group-item:hover {
  background-color: rgba(76, 175, 80, 0.8);
  color: white;
}
