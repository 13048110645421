@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,500,700|Varela+Round&display=swap');
@import './style-tabs.css';
@import './style-dropdown.css';

html * {
  font-family: 'Roboto', sans-serif;
}

.app-body {
  background-color: white !important;
}

.app-body .main {
  background-color: white !important;
  padding-bottom: 40px;
}

.page-detail > .row {
  border: 1px solid #f2f2f2;
  padding: 10px 5px;
  font-weight: bold;
  margin: 5px 0px;
}

.page-detail > .row:nth-child(even) {
  background-color: #f4f4f4;
}

.collapse-detail {
  border: 1px solid #f2f2f2;
  background: #f9f9f9;
  /* margin: 2px 10px; */
  padding: 5px 10px;
}
.collapse-detail .row {
  padding: 10px 0px;
}

.ant-message {
  z-index: 9999 !important;
}

.order-detail .row {
  padding: 5px 0px;
}

.btn-space {
  margin: 0px 3px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group > button,
.button-group > a {
  margin-bottom: 5px;
  margin-right: 5px;
}

.button-group button:last-child,
.button-group a:last-child {
  margin-right: 0px;
}
.button-group .btn-light {
  border-color: gray;
}

.option-button {
  /* border: 1px dashed gray;
  margin-bottom: 10px; */
  padding: 5px;
}

.option-button label {
  margin-left: 20px;
}
