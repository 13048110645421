.mo-tabs.nav-tabs {
  border-bottom: 0;
}

.mo-tabs a,
.mo-tabs a:hover,
.mo-tabs.nav-tabs .mo-tabs.nav-tabs:hover {
  padding: 0px;
  margin: 0px;
}

.mo-tabs.nav-tabs .nav-link {
  height: 40px;
  line-height: 40px;
  /* border-bottom: 1px solid white; */
}

.mo-tabs.nav-tabs .nav-link.active,
.mo-tabs.nav-tabs .nav-link:hover {
  border: none;
  color: rgba(0, 0, 0, 0.85);
}

.mo-tabs.nav-tabs {
  border-bottom: 1px solid rgb(217, 217, 217);
}
.mo-tabs.nav-tabs .nav-link.active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}

.mo-tabs.nav-tabs .nav-link {
  margin-right: 25px;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.mo-tabs.tab-content {
  border: none;
  transform: translateY(3px);
  padding: 0px;
}

.mo-tabs.tab-content .tab-pane {
  padding: 0px;
  padding-top: 10px;
}
